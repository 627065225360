import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Brand } from '../brand';
import { UserDetailAndAction } from '../userDetailAndAction';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretDown,
    faCaretLeft,
    faCaretUp,
    faFileCirclePlus,
    faFileLines,
} from '@fortawesome/free-solid-svg-icons';
import { Button, LoadingView, Modal } from '../reusableComponents';
import LayoutFilesListView from './layoutFileList';
import AddLayoutFile from './addLayoutFile';
import { getFileIcon, getFileIconColor } from '../../utils';
import { getFileNameWithoutExt } from './utils';
import { useGlobalCredits } from '../GlobalStateContext';
import { getUrl } from '../pdfDisplay/utils';

const ReportLayoutsSideView = (props) => {
    const {
        templateList,
        selectedTemplate,
        setSelectedTemplate,
        selectedLayout,
        setSelectedLayout,
        setIsRepoViewExpanded,
        closeSidebar,
    } = props;

    const actionTemplateRef = React.createRef();

    const user = JSON.parse(localStorage.getItem('current'));
    const [addFileMode, setAddFileMode] = useState(false);
    const [addFileToRepo, setAddFileToRepo] = useState(null);
    const [allTemplatesFilesData, setAllTemplatesFilesData] = useState(
        new Map()
    );
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const [templateWithAction, setTemplateWithAction] = useState(null);

    const scrollToWorkTemplate = (ref) => {
        ref.current?.scrollIntoView();
    };

    const getLayoutsList = async (templateName) => {
        try {
            setLoadingText('Loading Layout List...');
            setIsLoading(true);

            const email = user.email;
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getLayoutsList?email=${email}&template=${templateName}`);

            const encodedToken = encodeURIComponent(user.token);
            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            if (response) {
                const layoutsList = response.data.layoutsList;

                const repoData = {
                    filesData: layoutsList,
                    isExpanded: true,
                };

                updateRepoData(templateName, repoData);
            }

            setIsLoading(false);
            setLoadingText('');
        } catch (error) {
            setIsLoading(false);
            setLoadingText('');
            alert(error.message);
        }
    };

    const findIfExpanded = (repoName) => {
        return (
            allTemplatesFilesData &&
            allTemplatesFilesData.has(repoName) &&
            allTemplatesFilesData.get(repoName).isExpanded
        );
    };

    useEffect(() => {
        const repoFilesData = new Map();
        const initData = {
            filesData: [],
            isExpanded: false,
        };
        templateList.forEach((element) => {
            repoFilesData.set(element.filename, initData);
        });

        setAllTemplatesFilesData(repoFilesData);
    }, []);

    useEffect(() => {
        if (actionTemplateRef && templateWithAction) {
            scrollToWorkTemplate(actionTemplateRef);
        }

        return () => {};
    }, [actionTemplateRef, templateWithAction]);

    useEffect(() => {
        if (!addFileMode) {
            (async () => {
                for (let templateName of [...allTemplatesFilesData.keys()]) {
                    if (findIfExpanded(templateName)) {
                        await getLayoutsList(templateName);
                    }
                }
            })();
        }

        return () => {};
    }, [addFileMode]);

    const updateRepoData = (key, value) => {
        const deepCopyMap = new Map(allTemplatesFilesData);
        deepCopyMap.set(key, value);
        setAllTemplatesFilesData(deepCopyMap);
    };

    const updateSubData = (key, subKey, subValue) => {
        const data = allTemplatesFilesData.get(key);
        if (data) {
            const copyData = { ...data };
            copyData[subKey] = subValue;

            updateRepoData(key, copyData);
        }
    };

    const findLayoutsList = (templateName) => {
        const layoutData = allTemplatesFilesData.get(templateName);
        if (layoutData) {
            if (layoutData.filesData) return layoutData.filesData;
        }
        return [];
    };

    const Collections = ({ templateList }) => {
        return templateList.map((item, index) => {
            const isExpanded = findIfExpanded(item.filename);

            return (
                <div className='w-full text-texts-secondary300' key={index}>
                    {templateWithAction === item.filename && (
                        <div ref={actionTemplateRef} />
                    )}
                    <button
                        className={`w-full flex items-center justify-between px-2 py-0.5 border border-transparent hover:bg-backgrounds-primary100 hover:border hover:border-backgrounds-primary200 ${
                            selectedTemplate &&
                            selectedTemplate.filename === item.filename
                                ? 'bg-backgrounds-primary100 hover:border-backgrounds-primary200 shadow-inner'
                                : isExpanded
                                ? 'bg-backgrounds-settings100 border-backgrounds-settings500 shadow-inner'
                                : ''
                        } ${
                            isExpanded ? 'rounded-t-xl mt-2' : 'rounded-xl my-2'
                        }`}
                        onClick={async (event) => {
                            setTemplateWithAction(item.filename);

                            if (transactionMode) {
                                setTransactionMode(!transactionMode);
                            }
                            event.stopPropagation();
                            if (selectedTemplate !== item) {
                                setSelectedTemplate(item);
                                setSelectedLayout(null);
                            }

                            if (!isExpanded) {
                                await getLayoutsList(item.filename);
                            }
                            if (window.innerWidth < 1024) {
                                closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                            }
                        }}
                    >
                        <div className='flex items-center justify-center truncate'>
                            <FontAwesomeIcon
                                icon={getFileIcon(item.filename)}
                                //color={getFileIconColor(item.filename)}
                                className='mr-2 drop-shadow-sm text-icons-primary'
                            />
                            <div
                                className='truncate'
                                title={getFileNameWithoutExt(item.filename)}
                            >
                                {getFileNameWithoutExt(item.filename)}
                            </div>
                        </div>

                        <div className='flex items-center justify-center'>
                            <Button
                                className='ml-2 text-icons-primary'
                                onClick={async (event) => {
                                    setTemplateWithAction(item.filename);

                                    if (!isExpanded) {
                                        await getLayoutsList(item.filename);
                                    } else {
                                        updateSubData(
                                            item.filename,
                                            'isExpanded',
                                            false
                                        );
                                    }
                                    event.stopPropagation();
                                }}
                                hoverText={
                                    isExpanded
                                        ? 'Collapse and hide list of files in collection'
                                        : 'Expand and show list of files in collection'
                                }
                                icon={isExpanded ? faCaretUp : faCaretDown}
                                type={'warning'}
                            />

                            <Button
                                className='ml-2'
                                onClick={(event) => {
                                    setTemplateWithAction(item.filename);

                                    setAddFileToRepo(item);
                                    setAddFileMode(true);
                                    event.stopPropagation();
                                }}
                                hoverText={`Add new layout file in ${item.filename} collection`}
                                icon={faFileCirclePlus}
                                type={'success'}
                            />
                        </div>
                    </button>

                    <LayoutFilesListView
                        email={user.email}
                        currentRepo={item}
                        allTemplatesFilesData={allTemplatesFilesData}
                        selectedLayout={selectedLayout}
                        setSelectedLayout={setSelectedLayout}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        getLayoutsList={getLayoutsList}
                        closeSidebar={closeSidebar}
                        setTemplateWithAction={setTemplateWithAction}
                    />
                </div>
            );
        });
    };

    return (
        <div
            className={`z-0 h-full w-full transition-all duration-700 ease-in-out bg-transparent p-1 m-0 rounded-xl`}
        >
            <ReflexContainer orientation='horizontal'>
                <ReflexElement
                    className='header border-b-2 border-b-backgrounds-white'
                    style={{
                        minHeight: '8rem',
                        height: '8rem',
                        maxHeight: '8rem',
                    }}
                >
                    <div className='pane-content w-full h-full pb-2 px-1'>
                        <div className='w-full h-full flex flex-col justify-between items-center text-texts-secondary300'>
                            <Brand />

                            <div className='w-full flex items-center justify-between'>
                                <div className='w-full flex items-center truncate'>
                                    <FontAwesomeIcon
                                        icon={faFileLines}
                                        className='mr-2 drop-shadow-sm text-icons-primary'
                                    />
                                    <div className='font-semibold truncate'>
                                        Layout Collections
                                    </div>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <Button
                                        icon={faCaretLeft}
                                        className={'w-9 h-9 text-icons-primary'}
                                        onClick={() => {
                                            if (window.innerWidth > 1024) {
                                                // Adjust the breakpoint as per your design
                                                setIsRepoViewExpanded(false);
                                            } else {
                                                closeSidebar(); // Call the closeSidebar function passed from navbar.jsx
                                            }
                                        }}
                                        hoverText={
                                            'Collapse and hide Collections view'
                                        }
                                        type={'warning'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement flex={1}>
                    <div className='pane-content p-1'>
                        <div className='h-full w-full overflow-auto'>
                            {isLoading ? (
                                <div className='w-full h-32 flex justify-center items-center py-2'>
                                    <LoadingView loadingText={loadingText} />
                                </div>
                            ) : (
                                <Collections templateList={templateList} />
                            )}
                        </div>
                    </div>
                </ReflexElement>

                <ReflexElement
                    className='footer border-t-2 border-t-backgrounds-white'
                    style={{
                        minHeight: '9.5rem',
                        height: '9.5rem',
                        maxHeight: '9.5rem',
                    }}
                >
                    <div className='pane-content w-full h-full'>
                        <UserDetailAndAction />
                    </div>
                </ReflexElement>
            </ReflexContainer>

            {addFileMode && (
                <Modal
                    title={'Add New Layout'}
                    titleIcon={faFileCirclePlus}
                    iconColor={'icons-primary'}
                    onClose={() => {
                        setAddFileMode(false);
                    }}
                >
                    <AddLayoutFile
                        addNewMode={setAddFileMode}
                        template={addFileToRepo}
                        layoutsList={findLayoutsList(addFileToRepo.filename)}
                        setSelectedLayout={setSelectedLayout}
                        setSelectedTemplate={setSelectedTemplate}
                        getLayoutsList={getLayoutsList}
                    />
                </Modal>
            )}
        </div>
    );
};

export default ReportLayoutsSideView;
