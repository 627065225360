import React from 'react';
import { Switch } from 'antd';

const Toggle = ({
    toggleText,
    toggleTextClassName,
    className,
    checked,
    onChange,
    disabled,
}) => {
    return (
        <>
            <div
                className={`flex items-center w-full ${
                    className ? className : ''
                }`}
            >
                <div className={toggleTextClassName}>{toggleText}</div>
                <div>
                    <Switch
                        checked={checked}
                        onChange={onChange}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};

Toggle.defaultProps = {
    toggleTextClassName: 'w-1/4 font-semibold text-texts-tertiary',
};

export default Toggle;
