import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { ipAddress } from '../Constants';
import { getUrl } from '../components/pdfDisplay/utils';
const Temp = () => {
    const user = JSON.parse(localStorage.getItem('current'));

    let file_path =
        'rk@gmail.com/error-repo202308071275693/file-1691390129485-663148980.pdf';
    let encoded_file_path = encodeURIComponent(file_path);
    console.log(encoded_file_path);

    const handleInsertData = async () => {
        try {
            const dataToInsert = {
                id: 'rk@gmail2.com',
                prname: 'defaultprompt',
                prompt: 'this is the default prompt',
            };

            const response = await axios.post(
                getUrl(`http://localhost:5000/insertData`),
                dataToInsert
            );

            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    function downloadFile() {
        const email = 'rk123@gmail.com'; // Replace with the actual email
        const foldername = 'repo-test34440'; // Replace with the actual foldername

        fetch(
            getUrl(`http://$${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/download_excel_file`),
            {
                method: 'POST',
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: `email=${email}&foldername=${foldername}`,
            }
        )
            .then((response) => {
                const contentDispositionHeader = response.headers.get(
                    'Content-Disposition'
                );
                let filename = contentDispositionHeader
                    ? contentDispositionHeader.split('filename=')[1]
                    : 'downloaded_file.xlsx';
                const originalString = filename;
                const modifiedString = originalString.substring(
                    1,
                    originalString.length - 1
                );
                console.log(originalString);
                console.log(modifiedString);
                filename = modifiedString;
                return response.blob().then((blob) => ({ filename, blob }));
            })
            .then((data) => {
                const url = window.URL.createObjectURL(data.blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = data.filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => console.error('Error:', error));
    }
    function downloadFile2() {
        const email = 'rk123@gmail.com';
        const foldername = 'repo-test34440';

        fetch(
            getUrl(`http://$${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/download_excel_file`),
            {
                method: 'POST',
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: `email=${email}&foldername=${foldername}`,
            }
        )
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'downloaded_file.xlsx'; // Provide a default name
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => console.error('Error:', error));
    }
    function redownloadFile() {
        fetch(
            getUrl(`http://${process.env.FAST_API_SERVER_HOST}:${process.env.FAST_API_SERVER_PORT}/redownloadfiles`)
        )
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'downloaded_file.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => console.error('Error:', error));
    }

    const handleFetchData = async () => {
        try {
            const response = await axios.get(
                getUrl(`http://localhost:5000/getPrompts`),
                {
                    params: {
                        id: 'rk@gmail.com',
                    },
                }
            );

            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleUpdatePrompt = async () => {
        try {
            const response = await axios.post(
                getUrl(`http://localhost:5000/updatePrompt`),
                {
                    id: 'rk@gmail.com',
                    prompt: 'please update the prompt for rk@gmail.com',
                }
            );

            console.log(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleUpdatePrompt222 = async () => {
        try {
            const response = await axios.post(
               getUrl(`http://localhost:5000/updatePrompttable`),
                {
                    prid: parseInt(9), // Ensure prid is sent as an integer
                    prompt: 'this is the updated value for the prid 9 ',
                }
            );

            console.log('Prompt updated successfully:', response.data);
            // Add any further actions or state updates you want to do upon successful update
        } catch (error) {
            console.error('Error updating prompt:', error.message);
            // Handle error or display error message to the user
        }
    };

    const temp111 = `<table>
    <thead>
      <tr>
        <th>Food</th>
        <th>Calories</th>
        <th>Carbohydrates (g)</th>
        <th>Protein (g)</th>
        <th>Fat (g)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Apple</td>
        <td>95</td>
        <td>25</td>
        <td>0.5</td>
        <td>0.3</td>
      </tr>
      <tr>
        <td>Chicken Breast</td>
        <td>165</td>
        <td>0</td>
        <td>31</td>
        <td>3.6</td>
      </tr>
      <tr>
        <td>Brown Rice</td>
        <td>215</td>
        <td>45</td>
        <td>5</td>
        <td>0</td>
      </tr>
      <tr>
        <td>Salmon</td>
        <td>269</td>
        <td>0</td>
        <td>25</td>
        <td>15</td>
      </tr>
    </tbody>
  </table>`;

    const finalizedelete = async () => {
        try {
            const data = { id: user.email, foldername: 'test-repo79604' };
            const dynamicUrl = getUrl(`http://${
                process.env.REACT_APP_NODE_SERVER_HOST
            }:${
                process.env.REACT_APP_NODE_SERVER_PORT
            }/deleteinfo/${encodeURIComponent(JSON.stringify(data))}`);
            fetch(dynamicUrl, {
                method: 'DELETE',
            })
                .then((response) => {
                    if (response.ok) {
                        console.log('Deletion successful');
                    } else {
                        console.error('Deletion failed');
                    }
                })
                .catch((error) => {
                    console.error('An error occurred:', error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const getdocslist = async (val) => {
        try {
            val = 'repo-350211';
            const ipAddress = window.location.hostname;
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/alldocsinfo/${val}`);

            const response = await fetch(dynamicUrl);
            const data = await response.json();
            console.log(data);
            console.log(`the number of repos is ${data.length}`);
        } catch (error) {
            console.log(error);
        }
    };

    //<div dangerouslySetInnerHTML={{ __html: temp111 }} />
    const email = 'rk123@gmail.com';
    const foldername = 'test-1120928';
    const downloadFile111 = async () => {
        try {
            const response = await fetch(
                getUrl(`http://$${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/redownloadfiles?email=${email}&foldername=${foldername}`)
            );

            if (response.ok) {
                const contentDispositionHeader = response.headers.get(
                    'Content-Disposition'
                );
                if (contentDispositionHeader) {
                    const filename =
                        contentDispositionHeader.split('filename=')[1];
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            } else {
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDownloadClick = async () => {
        try {
            const response = await fetch(
                getUrl(`http://$${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/redownloadfile_excell?email=${email}&foldername=${foldername}`)
            );
            const disposition = response.headers.get('Content-Disposition');

            if (disposition && disposition.includes('attachment')) {
                const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = fileNameRegex.exec(disposition);
                let filename =
                    matches != null && matches[1] ? matches[1] : 'file.xlsx';
                const originalString = filename;
                const modifiedString = originalString.substring(
                    1,
                    originalString.length - 1
                );
                console.log(originalString);
                console.log(modifiedString);
                filename = modifiedString;
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                const data = await response.json();
                alert(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleGetQnaData = () => {
        const email11 = 'shubham@gmail.com';
        const foldername11 = 'Poly';
        fetch(
            getUrl(`http://$${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_data_qna/?email=${email11}&foldername=${foldername11}`)
        )
            .then((response) => response.json())
            .then((data) => console.log(data))
            .catch((error) => console.error('Error:', error));
    };

    const handleButtonClick111 = async () => {
        const email = 'a1@gmail.com';
        const foldername = '';
        try {
            const response = await fetch(
                getUrl(`http://${process.env.FAST_API_SERVER_HOST}:${process.env.FAST_API_SERVER_PORT}/?email=${email}&foldername=${foldername}`)
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log('API Response:', data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleButtonClick222 = async () => {
        try {
            const email = 'a1@gmail.com';
            const foldername = 'test-new';
            const response = await fetch(
                getUrl(`http://${process.env.FAST_API_SERVER_HOST}:${process.env.FAST_API_SERVER_PORT}/getExtractedTemplates?email=${email}&foldername=${foldername}`)
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data.rows);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleButtonClick333 = async () => {
        try {
            const email = 'a1@gmail.com';
            const response = await fetch(
                getUrl(`http://${process.env.FAST_API_SERVER_HOST}:${process.env.FAST_API_SERVER_PORT}/get_template_by_email?email=${email}`)
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const user_new = {
        email: 'rk1233@gmail.com',
        username: 'rk1233',
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJrNDQ1NEBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MDI0NjY0MDMsImV4cCI6MjAxODA0MjQwM30.nkiLSca2ZGQjscluwKjTPnLm1Is0SN-M1km1lOd_eE8',
        role: 'admin',
    };
    const encodedToken = encodeURIComponent(user.token);
    const check = async () => {
        const encodedEmail = encodeURIComponent(user.email);
        const encodedUsername = encodeURIComponent(user.username);
        const encodedToken = encodeURIComponent(user.token);

        try {
            const response = await fetch(getUrl(`http://localhost:5000/verify`), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
                body: JSON.stringify({
                    email: encodedEmail,
                    username: encodedUsername,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error:', error);
            alert(`Error: ${error.message}`);
        }
    };

    // Example usage

    async function getSharedRepos() {
        try {
            const repoid = 1245;
            const response = await fetch(
                getUrl(`http://localhost:5000/getSharedRepos/${repoid}`),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Shared Repositories:', result);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const [formData, setFormData] = useState({
        name: 'Rk pawar',
        email: 'Ritin@example.com',
        phone: '1234567890999',
        company: 'Example- mtree Corp',
        message:
            'Hello, this is a test message for the support page of chatpdf',
    });

    const handleSubmitEmail = async () => {
        try {
            const encodedToken = encodeURIComponent(user.token);

            const response = await fetch(getUrl('http://localhost:5000/submit-form'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                console.log('API response:', result);
            } else {
                console.error(
                    'API error:',
                    response.status,
                    response.statusText
                );
            }
        } catch (error) {
            console.error('API error:', error.message);
        }
    };

    const handleDebuggingFile = async () => {
        try {
            const encodedToken = encodeURIComponent(user.token);
            const email = 'an@gmail.com';
            const foldername = 'one';
            const response = await fetch(
                getUrl(`http://localhost:5000/debuggingFile`),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: encodedToken,
                    },
                    body: JSON.stringify({ email, foldername }),
                }
            );

            if (response.ok) {
                const csvContent = await response.text();

                // Create a Blob containing the CSV data
                const blob = new Blob([csvContent], { type: 'text/csv' });

                // Create an anchor element and set its href attribute to a data URL
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                // Specify the filename for the download
                link.download = 'Details.csv';

                // Append the anchor to the document body and trigger a click
                document.body.appendChild(link);
                link.click();

                // Remove the anchor from the document body
                document.body.removeChild(link);
            } else {
                console.error(
                    'Error downloading CSV:',
                    response.status,
                    response.statusText
                );
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const fetchData = async () => {
        const user = JSON.parse(localStorage.getItem('current'));
        const token = user.token; // Replace with your actual JWT token
        const email = 'user@example.com'; // Replace with the email
        const foldername = 'example-folder'; // Replace with the foldername

        try {
            const response = await fetch(
                getUrl(`http://$${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get-details?email=${email}&foldername=${foldername}`),
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                console.log(data);
            } else {
                console.error('Error:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    // Assuming this code is part of your frontend application

    const fetchModels = async () => {
        try {
            const response = await fetch(
                getUrl(`http://localhost:5000/getModelsArray`)
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Array from server:', data.array);
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const updateModel = async () => {
        const apiUrl =getUrl(`http://localhost:5000/insertModel`);
        const data = {
            email: 'an@gmail.com',
            model: 'gpt-3.5-turbo',
        };

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Response:', result);
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    // Call the function to make the API request

    // Call the function to fetch data

    const getEmailModel = async (email) => {
        const apiUrl = getUrl(`http://localhost:5000/getModel?email=${encodeURIComponent(
            email
        )}`);

        try {
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Response:', result);

            if (result.model) {
                console.log(`Model for email ${email}: ${result.model}`);
                console.log('************');
                console.log(result.model);
            } else {
                console.log(`Email ${email} not found`);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    const makeApiCall = async () => {
        try {
            const apiUrl = getUrl(`http://$${process.env.REACT_APP_FAST_API_HOST}:${
                process.env.REACT_APP_FAST_API_PORT
            }/folder-stats?folder_name=${'mkmc'}&user=${'k@gmail.com'}`);
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Folder Stats:', data);
            console.log(data.filesData);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const handleButtonClick009 = async () => {
        const email = '18jan@gmail.com';
        const mode = 'info';
        console.log('(((((((((((((((((((((((((((');
        try {
            const response = await fetch(
                getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/repo-details/${email}/${mode}`)
            );
            const data = await response.json();
            console.log(data);
            console.log(data[1].files);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchDataDebug = async () => {
        const repoId = 91;
        const filename = 'EXata-8.0-Cellular-ModelLibrary';
        const pageNumber = 89;

        try {
            const response = await fetch(
                getUrl(`http://$${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/debug/${repoId}/${filename}/${pageNumber}`)
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data); // Handle the response data as needed
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    async function getRepoidsInProcess(email) {
        const url = getUrl(`http://localhost:3000/get-repoids-in-process?email=${encodeURIComponent(
            email
        )}`);

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(
                    `Error: ${response.status} ${response.statusText}`
                );
            }

            const data = await response.json();
            console.log(data.repoids);
        } catch (error) {
            console.error('Error fetching repoids in process:', error);
            return [];
        }
    }

    return (
        <div className='flex-col'>
            <div>
                <button
                    className='ml-16'
                    onClick={() => {
                        getRepoidsInProcess('ritin.kumar@mtree.co.in');
                    }}
                >
                    update
                </button>
                <button className='ml-16' onClick={getSharedRepos}>
                    log shared
                </button>
                <h1>hello =</h1>
                <button className='ml-16' onClick={handleSubmitEmail}>
                    sendemail
                </button>
                <button className='ml-16' onClick={handleDebuggingFile}>
                    debuggingFile
                </button>

                <button className='ml-16' onClick={fetchData}>
                    fetchData
                </button>

                <button className='ml-16' onClick={fetchModels}>
                    fetchModels
                </button>
                <button className='ml-16' onClick={updateModel}>
                    updateModel
                </button>
                <button
                    className='ml-16'
                    onClick={() => {
                        getEmailModel('an@gmail.com');
                    }}
                >
                    getEmailModel
                </button>

                <button
                    className='ml-16'
                    onClick={() => {
                        makeApiCall();
                    }}
                >
                    getrepostats
                </button>

                <button
                    className='ml-16'
                    onClick={() => {
                        handleButtonClick009();
                    }}
                >
                    getdetailsrepos
                </button>
                <button
                    className='ml-16'
                    onClick={() => {
                        fetchDataDebug();
                    }}
                >
                    debugdata
                </button>
            </div>
        </div>
    );
};

export default Temp;
