import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Select,
    Empty,
    Table,
    Card,
    Pagination,
    Button as CustButton,
} from 'antd';
import moment from 'moment';
import { useGlobalCredits } from './GlobalStateContext';
import { Button, Container, Modal } from './reusableComponents';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    CartesianGrid,
} from 'recharts';
import {
    faCoins,
    faDownload,
    faFileDownload,
    faWallet,
} from '@fortawesome/free-solid-svg-icons';
import Addcredits from './addcredits';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Currency } from '../constants/currency';
import { getUrl } from './pdfDisplay/utils';

const { Option } = Select;

const TransactionList = () => {
    const [transactions, setTransactions] = useState([]);
    const [creditAddedTransaction, setCreditAddedTransaction] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [view, setView] = useState('chart'); // Toggle between 'chart' and 'table'
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(6);
    const [addCreditsModal, setAddCreditsModal] = useState(false);

    const user = JSON.parse(localStorage.getItem('current'));
    const email = user.email;
    const { credits } = useGlobalCredits();

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get(
                    getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/api/transactions/${email}`)
                );
                setTransactions(response.data);
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };

        fetchTransactions();
    }, [email, credits, selectedMonth]);

    useEffect(() => {
        const creditsAdded = async () => {
            try {
                const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/api/credits-added`);
                const authToken = encodeURIComponent(user.token);
                const headers = {
                    Authorization: authToken,
                    'Content-Type': 'application/json',
                };
                const response = await axios.get(dynamicUrl, {
                    headers: headers,
                });
                setCreditAddedTransaction(response.data);
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };
        creditsAdded();
    }, [email]);

    const downloadInvoice = async (id) => {
        try {
            if (!id) {
                alert('No Invoice Found!');
                return;
            }
            const dynamicUrl = getUrl(`http://${
                process.env.REACT_APP_NODE_SERVER_HOST
            }:${process.env.REACT_APP_NODE_SERVER_PORT}/download/${id.replace(
                /\.pdf$/,
                ''
            )}`);
            const authToken = encodeURIComponent(user.token);

            const headers = {
                Authorization: authToken,
            };

            const response = await axios.get(dynamicUrl, {
                headers: headers,
                responseType: 'blob',
            });

            if (response.status === 200) {
                const blob = response.data;

                const blobUrl = window.URL.createObjectURL(blob);

                const tempLink = document.createElement('a');
                tempLink.href = blobUrl;
                tempLink.setAttribute('download', `${id}`);
                tempLink.setAttribute('target', '_blank');

                document.body.appendChild(tempLink);

                tempLink.click();

                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(blobUrl);
            } else {
                alert(
                    `Failed to download file: ${response.status} ${response.statusText}`
                );
            }
        } catch (err) {
            console.log(err);
            alert('Something Went Wrong!');
        }
    };

    const getMonthlyTransactions = (transactions, month) => {
        const now = new Date();
        return transactions.filter((transaction) => {
            const transactionDate = new Date(transaction.timestamp);
            return (
                transactionDate.getMonth() === month &&
                transactionDate.getFullYear() === now.getFullYear()
            );
        });
    };

    const transformDataForChart = (transactions) => {
        const data = [];
        const operationsSet = new Set();

        // Initialize data array with days of the month and collect all operations
        for (let day = 1; day <= 31; day++) {
            data.push({
                day,
                operations: {},
            });
        }

        transactions.forEach((transaction) => {
            const transactionDate = new Date(transaction.timestamp);
            const day = transactionDate.getDate();
            const operation = transaction.operation;

            operationsSet.add(operation);

            if (!data[day - 1].operations[operation]) {
                data[day - 1].operations[operation] = 0;
            }
            data[day - 1].operations[operation] += transaction.cost;
        });

        // Prepare final data for chart and ensure all operations are present
        return data.map((entry) => {
            operationsSet.forEach((operation) => {
                if (!entry.operations[operation]) {
                    entry.operations[operation] = 0;
                }
            });
            return { day: entry.day, ...entry.operations };
        });
    };

    const calculateMonthlyTotal = (transactions) => {
        return transactions.reduce(
            (acc, transaction) => acc + transaction.cost,
            0
        );
    };

    const filteredTransactions = getMonthlyTransactions(
        transactions,
        selectedMonth
    );
    const chartData = transformDataForChart(filteredTransactions);
    const monthlyTotal = calculateMonthlyTotal(filteredTransactions);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className='custom-tooltip bg-backgrounds-primary100 rounded shadow-lg p-2'>
                    <p className='label'>{`Day: ${label}`}</p>
                    {payload.map((item) => (
                        <p
                            className='font-bold'
                            key={item.name}
                            style={{ color: item.color }}
                        >
                            {`${item.name}: ${
                                Currency[user.currency]?.symbol
                            }${item.value.toFixed(6)}`}
                        </p>
                    ))}
                </div>
            );
        }

        return null;
    };

    const columns = [
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
        },
        {
            title: 'Timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
        },
        {
            title: 'Repo name',
            dataIndex: 'reponame',
            key: 'reponame',
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            render: (cost) =>
                `${Currency[user.currency]?.symbol}${(cost / 1).toFixed(6)}`,
        },
        {
            title: 'User id',
            dataIndex: 'userid',
            key: 'userid',
        },
    ];
    const columnsTable2 = [
        {
            title: 'Timestamp',
            dataIndex: 'created',
            key: 'created',
            render: (timestamp) =>
                moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: 'Transaction Id',
            dataIndex: 'payment_id',
            key: 'payment_id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount) =>
                `${Currency[user.currency]?.symbol}${amount.toFixed(2)}`,
        },
        {
            title: 'Invoice',
            dataIndex: 'invoice_id',
            render: (invoice_id) => (
                <Button
                    text={'Download Invoice'}
                    className={'m-0'}
                    hoverText={`Download Invoice`}
                    onClick={() => downloadInvoice(invoice_id)}
                    type={'success'}
                    icon={faFileDownload}
                    disabled={!invoice_id}
                />
            ),
        },
    ];

    const isSmallScreen = window.innerWidth < 640;

    const getCurrentPageTransactions = (transactions) => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return transactions.slice(startIndex, endIndex);
    };
    const getCurrentPageTransactions2 = (transactions) => {
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        return creditAddedTransaction.slice(startIndex, endIndex);
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const rowClassName = (record, index) => {
        return index % 2 === 0
            ? 'bg-backgrounds-white'
            : 'bg-backgrounds-primary100';
    };

    // Filter months that have transactions
    const getAvailableMonths = (transactions) => {
        const availableMonths = new Set();

        transactions.forEach((transaction) => {
            const transactionDate = new Date(transaction.timestamp);
            availableMonths.add(transactionDate.getMonth());
        });

        return Array.from(availableMonths).sort((a, b) => a - b);
    };
    const availableMonths = getAvailableMonths(transactions);
    return (
        <div className='m-4 '>
            <div className='pb-4 flex justify-end'>
                <Button
                    className={`h-9 mr-2 px-2 p-3 text-backgrounds-primary300 `}
                    hoverText={'Add Credits'}
                    text={'Add Credits'}
                    icon={faCoins}
                    type={'success'}
                    onClick={() => {
                        setAddCreditsModal(true);
                    }}
                />
            </div>
            <Container
                title={'Transaction History'}
                icon={faWallet}
                iconColor={'icons-primary'}
            >
                <div className='flex justify-between mb-4 mt-3 p-3'>
                    <Select
                        defaultValue={new Date(
                            2024,
                            selectedMonth
                        ).toLocaleString('default', { month: 'long' })}
                        onChange={(value) => setSelectedMonth(value)}
                        className='w-32 mx-3'
                    >
                        {availableMonths.map((month) => (
                            <Option key={month} value={month}>
                                {new Date(2024, month).toLocaleString(
                                    'default',
                                    { month: 'long' }
                                )}
                            </Option>
                        ))}
                    </Select>
                    <CustButton
                        onClick={() =>
                            setView(view === 'chart' ? 'table' : 'chart')
                        }
                    >
                        {view === 'chart'
                            ? 'Switch to Table View'
                            : 'Switch to Chart View'}
                    </CustButton>
                </div>
                <div className='text-md text-texts-tertiary font-semibold flex flex-center justify-center'>
                    Monthly Total:
                    <span className='ml-2'>
                        {Currency[user.currency]?.symbol}
                        {monthlyTotal.toFixed(6)}
                    </span>
                </div>
                {view === 'chart' ? (
                    filteredTransactions.length === 0 ? (
                        <Empty description='No data to view' />
                    ) : (
                        <ResponsiveContainer width='100%' height={400}>
                            <BarChart data={chartData}>
                                <CartesianGrid strokeDasharray='1 1' />
                                <XAxis dataKey='day' />
                                <YAxis tickCount={10} />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />
                                {Object.keys(chartData[0])
                                    .filter((key) => key !== 'day')
                                    .map((key, index) => (
                                        <Bar
                                            key={index}
                                            dataKey={key}
                                            stackId='a'
                                            fill={getBarColor(index)}
                                        />
                                    ))}
                            </BarChart>
                        </ResponsiveContainer>
                    )
                ) : isSmallScreen ? (
                    <div className='grid grid-cols-1 gap-4 pl-2 pr-2'>
                        {filteredTransactions.length === 0 ? (
                            <Empty description='No data to view' />
                        ) : (
                            getCurrentPageTransactions(
                                filteredTransactions
                            ).map((transaction, index) => (
                                <Card
                                    key={transaction.tid}
                                    className={rowClassName(transaction, index)}
                                >
                                    <p>
                                        <span className='font-semibold mr-1'>
                                            Operation:
                                        </span>

                                        {transaction.operation}
                                    </p>
                                    <p>
                                        <span className='font-semibold mr-1'>
                                            Timestamp:
                                        </span>
                                        {transaction.timestamp}
                                    </p>
                                    <p>
                                        <span className='font-semibold mr-1'>
                                            Repo name:
                                        </span>
                                        {transaction.reponame}
                                    </p>
                                    <p>
                                        <span className='font-semibold' mr-1>
                                            Cost:
                                        </span>
                                        {Currency[user.currency]?.symbol}
                                        {transaction.cost.toFixed(6)}
                                    </p>
                                    <p>
                                        <span className='font-semibold mr-1'>
                                            User id:
                                        </span>
                                        {transaction.userid}
                                    </p>
                                </Card>
                            ))
                        )}
                        {filteredTransactions.length > 0 && (
                            <Pagination
                                className='md-3'
                                current={currentPage}
                                total={filteredTransactions.length}
                                pageSize={pageSize}
                                onChange={(page, pageSize) => {
                                    setCurrentPage(page);
                                    setPageSize(pageSize);
                                }}
                                showSizeChanger={false}
                            />
                        )}
                    </div>
                ) : (
                    <div>
                        <Table
                            columns={columns}
                            dataSource={getCurrentPageTransactions(
                                filteredTransactions
                            )}
                            rowKey='tid'
                            pagination={{
                                current: currentPage,
                                pageSize,
                                total: filteredTransactions.length,
                                onChange: (page, pageSize) => {
                                    setCurrentPage(page);
                                    setPageSize(pageSize);
                                },
                            }}
                            rowClassName={rowClassName}
                        />
                    </div>
                )}
            </Container>
            <div className='mt-10'></div>
            <Container
                title={'Credits added history'}
                icon={faWallet}
                iconColor={'green'}
            >
                {isSmallScreen ? (
                    <div className='grid grid-cols-1 gap-4'>
                        {getCurrentPageTransactions2().map((transaction) => (
                            <Card key={transaction.tid}>
                                <p>
                                    <span className='font-semibold mr-1'>
                                        Timestamp:
                                    </span>
                                    {moment
                                        .unix(transaction.created)
                                        .format('YYYY-MM-DD HH:mm:ss')}
                                </p>
                                <p>
                                    <span className='font-semibold mr-1'>
                                        Transaction Id:
                                    </span>
                                    {transaction.payment_id}
                                </p>
                                <p>
                                    <span className='font-semibold mr-1'>
                                        Status:
                                    </span>
                                    {transaction.status}
                                </p>
                                <p>
                                    <span className='font-semibold mr-1'>
                                        Amount:
                                    </span>
                                    {`${
                                        Currency[user.currency]?.symbol
                                    }${transaction.amount.toFixed(2)}`}
                                </p>
                                <p>
                                    <span className='font-semibold'>
                                        Invoice:
                                    </span>
                                    <div
                                        className='cursor-pointer flex ml-5'
                                        onClick={() =>
                                            downloadInvoice(
                                                transaction.invoice_id
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </div>
                                </p>
                            </Card>
                        ))}
                        {/* Pagination for small screen */}
                        <Pagination
                            className='mt-4'
                            current={currentPage}
                            total={creditAddedTransaction.length}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                        />
                    </div>
                ) : (
                    <Table
                        dataSource={creditAddedTransaction}
                        columns={columnsTable2}
                        rowKey='tid'
                        pagination={{ pageSize: 7 }}
                        rowClassName={rowClassName}
                        bordered
                    />
                )}
            </Container>
            <div className='pb-10'></div>
            {addCreditsModal && (
                <Modal
                    title={'Add Credits'}
                    titleIcon={faCoins}
                    iconColor={'green'}
                    onClose={() => setAddCreditsModal(false)}
                >
                    <Addcredits />
                </Modal>
            )}
        </div>
    );
};

// Utility function to get a color for each bar segment
const getBarColor = (index) => {
    const colors = ['#7DD3FC', '#94A3B8', '#0EA5E9', '#A1A1AA', '#7DD3FC'];
    return colors[index % colors.length];
};

export default TransactionList;
