import React from 'react';

export const LoadingView = ({ loadingText, inLine }) => {
    return (
        <div
            className={`flex h-14 ${
                inLine ? '' : 'flex-col'
            } justify-center items-center text-texts-tertiary`}
        >
            {inLine ? <div className='mr-2'>{loadingText}</div> : <></>}
            <div className='w-30 h-14 flex items-center justify-center'>
                <div className='w-3 h-3 bg-buttons-alert400 rounded-full animate-ping mx-2'></div>
                <div className='w-3 h-3 bg-backgrounds-primary400 rounded-full animate-ping mx-2'></div>
                <div className='w-3 h-3 bg-backgrounds-settings500 rounded-full animate-ping mx-2'></div>
                <div className='w-3 h-3 bg-backgrounds-primary400 rounded-full animate-ping mx-2'></div>
            </div>
            {!inLine ? <div className='mt-2'>{loadingText}</div> : <></>}
        </div>
    );
};
