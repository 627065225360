import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretLeft,
    faCaretRight,
    faComment,
    faFile,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
} from '@fortawesome/free-solid-svg-icons';
import { PdfViewer } from './pdfViewer';
import { PdfActions } from './pdfActions';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement } from 'react-reflex';
import { Button, Pagination } from '../reusableComponents';
import { getFileFormat, getFileIcon, getFileIconColor } from '../../utils';
import { Mode } from '../../constants/modeTypes';
import { getUrl } from './utils';

const scaleSteps = 0.1;
const minScale = 0.5;
const maxScale = 3;

export const PdfContainer = ({ activeQuestion, togglePdfVisibility }) => {
    const [activeChunkData, setActiveChunkData] = useState(null);
    const [chunksData, setChunksData] = useState([]);
    const [pdfUrl, setPdfUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const [activePageNumber, setActivePageNumber] = useState(0);
    const [activePageContent, setActivePageContent] = useState(null);
    const [totalPageNumbers, setTotalPageNumbers] = useState(0);
    const [scale, setScale] = useState(window.innerWidth < 1024 ? 0.6 : 1);

    useEffect(() => {
        if (
            activeQuestion &&
            activeQuestion.chunksData &&
            activeQuestion.chunksData.length > 0
        ) {
            const { chunksData } = activeQuestion;
            // Sorting the chunks according to page number.
            chunksData.sort(function (chunk, anotherChunk) {
                return chunk.pageNumber - anotherChunk.pageNumber;
            });
            const firstChunkData = chunksData[0];

            setActiveChunkData(firstChunkData);
            setChunksData(chunksData);
        }
    }, [activeQuestion]);

    useEffect(() => {
        if (activeChunkData) {
            const { email, folderName, fileName } = activeChunkData;

            const pageNumber = activeChunkData.pageNumber;
            const pageContent = { chunks: [activeChunkData.pageContent] };
            const actualFileName = activeChunkData.actualFileName;
            const fileExtension = getFileFormat(fileName);
            const pdfFileName = fileName.replace(fileExtension, 'pdf');
            const path = `${email}/${folderName}/${pdfFileName}`;
            const encodedPath = encodeURIComponent(path);
            const pdfFileUrl = getUrl(`http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/get_pdf/?file_path=${encodedPath}&mode=${Mode.CHAT}`);

            setPdfUrl(pdfFileUrl);
            setFileName(actualFileName);
            setActivePageNumber(pageNumber);
            setActivePageContent(pageContent);
        }
    }, [activeChunkData]);

    const handleSourceChange = (index) => {
        const newActiveChunk = chunksData[index];
        const pageNumber = newActiveChunk.pageNumber;
        setActiveChunkData(newActiveChunk);
        setActivePageNumber(pageNumber);
    };

    return (
        <ReflexContainer orientation='horizontal'>
            {window.innerWidth <= 1024 && activeQuestion && (
                <div className='fixed bottom-24 right-8 z-20 scale-110'>
                    <Button
                        className={'mx-2 w-30 h-30'}
                        //text={'Chat'}
                        hoverText={'View Chat'}
                        icon={faComment}
                        onClick={(e) => {
                            togglePdfVisibility();
                        }}
                        type={'success'}
                    />
                </div>
            )}
            <ReflexElement
                className='header border-b'
                style={{
                    minHeight: '3.5rem',
                    height: '3.5rem',
                    maxHeight: '3.5rem',
                }}
            >
                <div className='pane-content w-full h-full p-2'>
                    <div className='flex h-full items-center my-0 mx-2'>
                        <div className='w-1/2 my-1 text-texts-secondary300 truncate'>
                            {fileName ? (
                                <div className='flex items-center truncate'>
                                    <FontAwesomeIcon
                                        icon={getFileIcon(fileName)}
                                        color={getFileIconColor(fileName)}
                                        className='mr-2 drop-shadow'
                                    />
                                    <div className='truncate font-semibold'>
                                        {fileName}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className='w-1/2'>
                            <PdfActions
                                pageNumber={activePageNumber}
                                totalPageNumbers={totalPageNumbers}
                                setPageNumber={setActivePageNumber}
                            />
                        </div>
                    </div>
                </div>
            </ReflexElement>

            <ReflexElement flex={1}>
                <div className='pane-content'>
                    <PdfViewer
                        pdfFile={pdfUrl}
                        fileName={fileName}
                        highlightTexts={activePageContent}
                        pageNumber={activePageNumber}
                        activeChunkPage={
                            activeChunkData ? activeChunkData.pageNumber : 0
                        }
                        setPageNumber={setActivePageNumber}
                        setTotalPageNumbers={setTotalPageNumbers}
                        scale={scale}
                    />
                </div>
            </ReflexElement>

            <ReflexElement
                className='footer border-t'
                style={{
                    minHeight: '4rem',
                    height: '4rem',
                    maxHeight: '4rem',
                }}
            >
                <div className='pane-content w-full h-full'>
                    <div className='flex items-center justify-between w-full h-full px-4'>
                        <div className='flex justify-center items-center mr-2'>
                            <div className='flex justify-center items-center text-texts-secondary300 truncate'>
                                <FontAwesomeIcon
                                    icon={faFile}
                                    className='mr-2'
                                />
                                <div className='truncate font-semibold'>
                                    Document Source
                                </div>
                            </div>
                            <Pagination
                                activeId={
                                    activeQuestion ? activeQuestion.qid : null
                                }
                                itemsCount={chunksData.length}
                                itemsPerPage={1}
                                handlePageChange={handleSourceChange}
                                previousLabel={
                                    <Button
                                        hoverText={'Go to previous source'}
                                        icon={faCaretLeft}
                                        type={'warning'}
                                    />
                                }
                                nextLabel={
                                    <Button
                                        hoverText={'Go to next source'}
                                        icon={faCaretRight}
                                        type={'warning'}
                                    />
                                }
                            />
                        </div>

                        {/* Scale actions */}
                        <div className='flex items-center rounded-full ml-2 border'>
                            <Button
                                icon={faMagnifyingGlassMinus}
                                type={'warning'}
                                onClick={() => {
                                    const newScale = parseFloat(
                                        parseFloat(scale) - scaleSteps
                                    ).toFixed(1);
                                    setScale(newScale);
                                }}
                                hoverText={
                                    'Decrease the viewing scale of document'
                                }
                                disabled={scale <= minScale}
                            />
                            <div className='w-6 flex justify-center text-sm mx-1 text-texts-secondary300 font-semibold text-nowrap font-semibold'>
                                {scale}x
                            </div>
                            <Button
                                icon={faMagnifyingGlassPlus}
                                type={'warning'}
                                onClick={() => {
                                    const newScale = parseFloat(
                                        parseFloat(scale) + scaleSteps
                                    ).toFixed(1);
                                    setScale(newScale);
                                }}
                                hoverText={
                                    'Increase the viewing scale of document'
                                }
                                disabled={scale >= maxScale}
                            />
                        </div>
                    </div>
                </div>
            </ReflexElement>
        </ReflexContainer>
    );
};
