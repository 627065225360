import logo from './images/QuyvaLogo.png';

// App info.
export const appName = 'Quyva';
export const appLogo = logo;
export const appVersion = '1.0.0';
export const companyNumber = '';
export const companyEmail = 'quyva@mtree.co.in';

// Developer Options
export const developerMode = true;

//Company Details
export const CompanyDetails = Object.freeze({
    POWERED_BY: 'MTree Software (P) Ltd.',
    NAME: 'MTree Software (P) Ltd.',
    ADDRESS: 'C-56 A/2, IVth Floor, Sector 62, Noida',
    PHONE: companyNumber,
    EMAIL: companyEmail,
    WEBSITE: 'www.mtree.co.in',
    ABOUT_US_TITLE:
        'A professional software product development services company',
    ABOUT_US_DESCRIPTION:
        'We focus on understanding business requirements and effective software development for all sizes and complexities of businesses and organizations. Our constant endeavor is to use latest technologies and following industry best practices for building robust softwares for our clients.',
});

//Global constants
export const ipAddress = window.location.hostname;
