import React, { useEffect, useState } from 'react';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { CollapsedSideBar } from '../collapsedSideBar';
import { NoInteractionView } from '../reusableComponents';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../navbar';
import ReportLayoutsDesignerView from './reportLayoutsDesignerView';
import ReportLayoutsSideView from './reportLayoutsSideView';
import BeforeSelectView from './beforeSelectView';
import TransactionList from '../Transactionlist';
import { useGlobalCredits } from '../GlobalStateContext';
const minWindowSize = 50;
export const ReportLayoutsView = ({
    setActiveTemplate,
    templateList,
    setTemplateList,
    fetchTemplateList,
    newNotification,
    setNewNotification,
    viewNotifications,
    setViewNotifications,
    messages,
    socket,
    isSideViewExpanded,
    setIsSideViewExpanded,
}) => {
    const user = JSON.parse(localStorage.getItem('current'));
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedLayout, setSelectedLayout] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Change 1024 to your desired breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await fetchTemplateList(user, setActiveTemplate, setTemplateList);
            setIsLoading(false);
        })();
        return () => {};
    }, []);

    if (isLoading) {
        return <NoInteractionView viewText={'Loading ...'} />;
    }

    return (
        <div className='z-0 w-[99vw] h-[98vh] m-auto my-2'>
            <ReflexContainer orientation='vertical'>
                {!isMobile && (
                    <ReflexElement
                        className='left-pane mr-0.5'
                        minSize={isSideViewExpanded ? 250 : minWindowSize}
                        size={isSideViewExpanded ? 350 : minWindowSize}
                        maxSize={isSideViewExpanded ? 700 : minWindowSize}
                    >
                        <div className='pane-content w-full h-full bg-transparent'>
                            {isSideViewExpanded ? (
                                <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl p-2 z-10'>
                                    <ReportLayoutsSideView
                                        templateList={templateList}
                                        selectedTemplate={selectedTemplate}
                                        setSelectedTemplate={
                                            setSelectedTemplate
                                        }
                                        selectedLayout={selectedLayout}
                                        setSelectedLayout={setSelectedLayout}
                                        setIsRepoViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                    />
                                </div>
                            ) : (
                                <CollapsedSideBar
                                    icon={faFileLines}
                                    iconColor={'icons-primary'}
                                    title={'Layout Collections'}
                                    setIsRepoViewExpanded={
                                        setIsSideViewExpanded
                                    }
                                />
                            )}
                        </div>
                    </ReflexElement>
                )}

                <ReflexSplitter
                    style={{
                        border: 0,
                        background: 'white',
                        width: '6px',
                        zIndex: 10,
                        height: '98vh',
                    }}
                />

                <ReflexElement className='right-pane ml-0.5'>
                    <div className='pane-content w-full h-full bg-transparent'>
                        <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl z-0'>
                            <ReflexContainer orientation='horizontal'>
                                <ReflexElement
                                    className='header'
                                    style={{
                                        minHeight: '4rem',
                                        height: '4rem',
                                        maxHeight: '4rem',
                                    }}
                                >
                                    <div className='pane-content w-full h-full p-2'>
                                        <Navbar
                                            newNotification={newNotification}
                                            setNewNotification={
                                                setNewNotification
                                            }
                                            viewNotifications={
                                                viewNotifications
                                            }
                                            setViewNotifications={
                                                setViewNotifications
                                            }
                                            messages={messages}
                                            socket={socket}
                                            templateList={templateList}
                                            selectedTemplate={selectedTemplate}
                                            setSelectedTemplate={
                                                setSelectedTemplate
                                            }
                                            selectedLayout={selectedLayout}
                                            setSelectedLayout={
                                                setSelectedLayout
                                            }
                                            setIsRepoViewExpanded={
                                                setIsSideViewExpanded
                                            }
                                        />
                                    </div>
                                </ReflexElement>

                                <ReflexElement flex={1}>
                                    <div className='pane-content h-full w-full p-2'>
                                        {transactionMode ? (
                                            <>
                                                <TransactionList />
                                            </>
                                        ) : (
                                            <>
                                                {selectedLayout === null ? (
                                                    <BeforeSelectView />
                                                ) : (
                                                    <ReportLayoutsDesignerView
                                                        user={user}
                                                        selectedLayout={
                                                            selectedLayout
                                                        }
                                                        selectedTemplate={
                                                            selectedTemplate
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </ReflexElement>
                            </ReflexContainer>
                        </div>
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};
