import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar';
import MainArea from './MainArea';
import Navbar from './navbar';
import BeforeSelect from './BeforeSelect';
import 'react-reflex/styles.css';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { CollapsedSideBar } from './collapsedSideBar';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import TransactionList from './Transactionlist';
import { useGlobalCredits } from './GlobalStateContext';
import { DataType } from '../enums';
const Main = ({
    isAdmin,
    setIsAdmin,
    newNotification,
    setNewNotification,
    viewNotifications,
    setViewNotifications,
    messages,
    socket,
    processingRepos,
    isSideViewExpanded,
    setIsSideViewExpanded,
    templateList,
}) => {
    const [repo_to_chat, set_repo_to_chat] = useState(-1);
    const [fine_tuned_chat, set_fine_tuned_chat] = useState(false);
    const [fileToChat, setFileToChat] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    //responsive mode functions and variables
    const [showPdf, setShowPdf] = useState(false);
    const [onButton, setOnButton] = useState(false);
    useEffect(() => {
        // Reset both states to false when the component mounts or updates
        setShowPdf(false);
        setOnButton(false);
    }, []);

    const togglePdfVisibility = (reset) => {
        if (typeof reset === DataType.STRING) {
            setShowPdf(false);
            setOnButton(false);
        } else {
            setShowPdf((prevState) => !prevState);
            setOnButton((prevState) => !prevState);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024); // Change 1024 to your desired breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const updateRepoToChat = (value) => {
        set_repo_to_chat(value);
    };

    return (
        <div className='z-0 w-[99vw] h-[98vh] m-auto my-2'>
            <ReflexContainer orientation='vertical'>
                {!isMobile && (
                    <ReflexElement
                        className='left-pane mr-0.5'
                        minSize={isSideViewExpanded ? 250 : 50}
                        size={isSideViewExpanded ? 350 : 50}
                        maxSize={isSideViewExpanded ? 700 : 50}
                    >
                        <div className='pane-content w-full h-full bg-transparent'>
                            {isSideViewExpanded ? (
                                <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl p-2 z-10'>
                                    <Sidebar
                                        chatrepo={repo_to_chat}
                                        templateList={templateList}
                                        chatrepofunction={updateRepoToChat}
                                        set_fine_tuned={set_fine_tuned_chat}
                                        fine_tune={fine_tuned_chat}
                                        fileToChat={fileToChat}
                                        setFileToChat={setFileToChat}
                                        setIsRepoViewExpanded={
                                            setIsSideViewExpanded
                                        }
                                        isAdmin={isAdmin}
                                        setIsAdmin={setIsAdmin}
                                        mode={'qna'}
                                        socket={socket}
                                        processingRepos={processingRepos}
                                        setViewNotifications={
                                            setViewNotifications
                                        }
                                    />
                                </div>
                            ) : (
                                <CollapsedSideBar
                                    icon={faFolder}
                                    iconColor={'icons-primary'}
                                    title={'Repositories'}
                                    setIsRepoViewExpanded={
                                        setIsSideViewExpanded
                                    }
                                />
                            )}
                        </div>
                    </ReflexElement>
                )}

                <ReflexSplitter
                    style={{
                        border: 0,
                        background: 'white',
                        width: '6px',
                        zIndex: 10,
                        height: '98vh',
                    }}
                />

                <ReflexElement className='right-pane ml-0.5'>
                    <div className='pane-content w-full h-full bg-transparent'>
                        <div className='w-full h-full border border-2 overflow-auto bg-backgrounds-slate rounded-xl z-0'>
                            <ReflexContainer orientation='horizontal'>
                                <ReflexElement
                                    className='header'
                                    style={{
                                        minHeight: '4rem',
                                        height: '4rem',
                                        maxHeight: '4rem',
                                    }}
                                >
                                    <div className='pane-content w-full h-full p-2'>
                                        <Navbar
                                            isAdmin={isAdmin}
                                            repo_to_chat={repo_to_chat}
                                            updateRepoToChat={updateRepoToChat}
                                            set_fine_tuned_chat={
                                                set_fine_tuned_chat
                                            }
                                            fine_tuned_chat={fine_tuned_chat}
                                            fileToChat={fileToChat}
                                            setFileToChat={setFileToChat}
                                            setIsRepoViewExpanded={
                                                setIsSideViewExpanded
                                            }
                                            setIsAdmin={setIsAdmin}
                                            mode={'qna'}
                                            newNotification={newNotification}
                                            setNewNotification={
                                                setNewNotification
                                            }
                                            viewNotifications={
                                                viewNotifications
                                            }
                                            setViewNotifications={
                                                setViewNotifications
                                            }
                                            messages={messages}
                                            socket={socket}
                                            processingRepos={processingRepos}
                                            togglePdfVisibility={
                                                togglePdfVisibility
                                            }
                                        />
                                    </div>
                                </ReflexElement>

                                <ReflexElement flex={1}>
                                    <div className='pane-content h-full w-full p-2'>
                                        {transactionMode ? (
                                            <>
                                                <TransactionList />
                                            </>
                                        ) : (
                                            <>
                                                {repo_to_chat !== -1 ? (
                                                    <MainArea
                                                        showPdf={showPdf}
                                                        onButton={onButton}
                                                        setShowPdf={setShowPdf}
                                                        setOnButton={
                                                            setOnButton
                                                        }
                                                        togglePdfVisibility={
                                                            togglePdfVisibility
                                                        }
                                                        repo_to_chat={
                                                            repo_to_chat
                                                        }
                                                        fine_tuned_mode={
                                                            fine_tuned_chat
                                                        }
                                                        fileToChat={fileToChat}
                                                        isAdmin={isAdmin}
                                                    />
                                                ) : (
                                                    <BeforeSelect />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </ReflexElement>
                            </ReflexContainer>
                        </div>
                    </div>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
};

export default Main;
