import React, { useEffect, useState } from 'react';
import { Container, NoInteractionView } from './reusableComponents';
import {
    faFileExport,
    faFolder,
    faTable,
} from '@fortawesome/free-solid-svg-icons';
import { NoDataView } from './noDataView';
import Extraction from './Extraction';
import { getFileIcon, getFileIconColor } from '../utils';
import { ExtractDownloadView } from './extractDownloadView';
import { ReportModal } from './reportLayouts/report';


const getDropDownList = (templateList, activeTemplate, setActiveTemplate) => {
    const dropDownList = [];
    if (templateList) {
        templateList.forEach((extractedData, index) => {
            const data = {
                key: index,
                label: extractedData.filename,
                icon: getFileIcon(extractedData.filename),
                iconColor: getFileIconColor(extractedData.filename),
                selected: activeTemplate.filename === extractedData.filename,
                action: () => setActiveTemplate(extractedData),
            };

            dropDownList.push(data);
        });
    }

    return dropDownList;
};


const MainAreaInfo = ({
    workOnRepo,
    templateList,
    activeTemplate,
    setActiveTemplate,
    email, // Add email as a prop
    folderName, // Add folderName as a prop
    processingRepos,
    socket,
    setViewNotifications,
    fetchDownloadList,
    getTableDataSource,
    setDownloadList,
    downloadList,
    setShowReportModal,
    setWorkOnTemplate,
    showReportModal,
    workOnTemplate,
}) => {
    const [extractMode, setExtractMode] = useState(false);
    const [dropDownList, setDropDownList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const MAX_LENGTH_LARGE_SCREEN = 32;
    const MAX_LENGTH_SMALL_SCREEN = 10;
    const BREAKPOINT_WIDTH = 1024;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const dropDownList = getDropDownList(
            templateList,
            activeTemplate,
            setActiveTemplate
        );
        setDropDownList(dropDownList);

        return () => {};
    }, [activeTemplate, templateList, setActiveTemplate]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await fetchDownloadList(workOnRepo.name,workOnRepo.repoid,workOnRepo.email);
            setIsLoading(false);
        })();

        return () => {};
    }, [workOnRepo.name]);

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength - 3) + '...';
    };

    const getMaxLength = () => {
        return windowWidth >= BREAKPOINT_WIDTH
            ? MAX_LENGTH_LARGE_SCREEN
            : MAX_LENGTH_SMALL_SCREEN;
    };

    const actions = [
        {
            dropDownList: dropDownList,
            showButtonText: windowWidth >= BREAKPOINT_WIDTH,
            buttonText: activeTemplate
                ? truncateText(activeTemplate.filename, getMaxLength())
                : 'Select Template',
            buttonTitle: 'Template',
            buttonHoverText: 'Select template for extraction',
            // icon: faCaretDown;
            backgroundColor: 'white',
            //textColor: 'lightblue',
            buttonClassName:
                'flex items-center justify-between mr-2 overflow-hidden text-ellipsis whitespace-nowrap',
            style: {
                maxWidth: '18rem',
                minWidth: '10rem',
                width: 'auto',
            },
            iconClassName: `ml-2`,
            disabled: false,
            type: 'dropdown',
        },
        {
            text: windowWidth >= BREAKPOINT_WIDTH ? 'Extract Data' : '',
            icon: faFileExport,
            action: () => {
                setExtractMode(true);
                setViewNotifications(true);
            },
            hoverText:
                !activeTemplate || processingRepos.length
                    ? 'Extraction disabled as a heavy task is under progress'
                    : `Extract data from ${activeTemplate.filename} template`,
            type: 'success',
            disabled: !activeTemplate || processingRepos.length,
        },
    ];

    if (isLoading)
        return <NoInteractionView viewText={'Loading extracted list ...'} />;

    return (
        <Container
            title={workOnRepo.name}
            icon={faFolder}
            iconColor={'icons-primary'}
            actions={actions}
        >
            <div className='w-full h-full p-3'>
                {extractMode && (
                    <Extraction
                        repo={workOnRepo}
                        setExtractMode={setExtractMode}
                        getTableDataSource={()=>{getTableDataSource(workOnRepo.email,workOnRepo.name,setShowReportModal,setWorkOnTemplate)}}
                        setDownloadList={setDownloadList}
                        activeTemplate={activeTemplate}
                        fetchDownloadList={() => {
                            fetchDownloadList(workOnRepo.name,workOnRepo.repoid,workOnRepo.email);
                        }}
                        socket={socket}
                    />
                )}
                <ExtractDownloadView
                    downloadList={downloadList}
                    setShowReportModal={setShowReportModal}
                    setWorkOnTemplate={setWorkOnTemplate}
                    email={workOnRepo.email} // Pass email as prop
                    folderName={workOnRepo.name} // Pass folderName as prop
                />
                {downloadList.length === 0 ? (
                    <NoDataView
                        text={'No extracted data to view'}
                        icon={faTable}
                        iconColor={'icons-primary100'}
                    />
                ) : (
                    <></>
                )}
            </div>
            {showReportModal && (
                <ReportModal
                    extractionRepo={workOnRepo}
                    setShowReportModal={setShowReportModal}
                    template={workOnTemplate}
                />
            )}
        </Container>
    );
};

export default MainAreaInfo;
