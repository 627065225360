import React, { createContext, useState, useContext, useEffect } from 'react';
import { AuthStatus } from '../constants/authStatus';
import { useGlobalCredits } from '../components/GlobalStateContext';
import axios from 'axios';
import { CustomEvents } from '../constants/customEvents';
import { getUrl } from '../components/pdfDisplay/utils';

// Create AuthContext
const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
    const { transactionMode, setTransactionMode } = useGlobalCredits();

    const fetchProfileData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('current'));
            const encodedToken = encodeURIComponent(user.token);
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/get_profile_data`);
            const response = await axios.get(dynamicUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: encodedToken,
                },
            });

            // Save user data in localStorage
            localStorage.setItem('current', JSON.stringify(response.data));

            // Update React state with the fetched data
            setUser(response.data);

            // Update authentication state based on email verification status
            if (
                response.data &&
                Object.keys(response.data).length !== 0 &&
                Object.keys(response.data).includes('isEmailVerified') &&
                response.data.isEmailVerified
            ) {
                setAuthenticated(AuthStatus.AUTHENTICATED);
            } else if (
                response.data &&
                Object.keys(response.data).length !== 0 &&
                Object.keys(response.data).includes('isEmailVerified') &&
                !response.data.isEmailVerified
            ) {
                setAuthenticated(AuthStatus.REQUIRE_MAIL_VERIFICATION);
            } else {
                setAuthenticated(AuthStatus.NOT_AUTHENTICATED);
            }

            setTransactionMode(false);
        } catch (err) {
            console.error(err.message);
            setAuthenticated(AuthStatus.NOT_AUTHENTICATED);
            setTransactionMode(false);
        }
    };

    function isAuthenticatedValue() {
        const user = JSON.parse(localStorage.getItem('current'));
        if (
            user &&
            Object.keys(user).length !== 0 &&
            Object.keys(user).includes('isEmailVerified') &&
            user.isEmailVerified
        ) {
            return AuthStatus.AUTHENTICATED;
        } else if (
            user &&
            Object.keys(user).length !== 0 &&
            Object.keys(user).includes('isEmailVerified') &&
            !user.isEmailVerified
        ) {
            return AuthStatus.REQUIRE_MAIL_VERIFICATION;
        } else {
            return AuthStatus.NOT_AUTHENTICATED;
        }
    }
    const [authenticated, setAuthenticated] = useState(isAuthenticatedValue());
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem('current'))
    );

    // Login function
    const login = (user) => {
        // Save user data in localStorage
        localStorage.setItem('current', JSON.stringify(user));

        // Notify other tabs of the login event
        localStorage.setItem(CustomEvents.LOGIN, Date.now());

        // Update local React state
        setUser(user);

        // Update authentication state based on email verification status
        if (
            user &&
            Object.keys(user).length !== 0 &&
            Object.keys(user).includes('isEmailVerified') &&
            user.isEmailVerified
        ) {
            setAuthenticated(AuthStatus.AUTHENTICATED);
        } else if (
            user &&
            Object.keys(user).length !== 0 &&
            Object.keys(user).includes('isEmailVerified') &&
            !user.isEmailVerified
        ) {
            setAuthenticated(AuthStatus.REQUIRE_MAIL_VERIFICATION);
        } else {
            setAuthenticated(AuthStatus.NOT_AUTHENTICATED);
        }

        setTransactionMode(false);
    };

    // Logout function
    const logout = async () => {
        try {
            // Set a logout event in localStorage to notify other tabs
            localStorage.setItem(CustomEvents.LOGOUT, Date.now());

            // Clear all localStorage data
            localStorage.clear();

            // Update React state to reflect the user is logged out
            setUser(null);
            setAuthenticated(AuthStatus.NOT_AUTHENTICATED);
            setTransactionMode(false);

            // Send the logout request to the server
            const dynamicUrl = getUrl(`http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/logout`);
            const response = await fetch(dynamicUrl, {
                method: 'POST',
                credentials: 'include',
            });

            // Optionally handle the server response here if needed
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (
            authenticated === AuthStatus.AUTHENTICATED ||
            authenticated === AuthStatus.REQUIRE_MAIL_VERIFICATION
        ) {
            fetchProfileData();
        }
        const handleStorageChange = (event) => {
            if (event.key === CustomEvents.LOGIN) {
                // Handle login event
                const newUser = JSON.parse(localStorage.getItem('current'));
                if (newUser) {
                    setUser(newUser);
                    if (
                        Object.keys(newUser).length !== 0 &&
                        Object.keys(newUser).includes('isEmailVerified') &&
                        newUser.isEmailVerified
                    ) {
                        setAuthenticated(AuthStatus.AUTHENTICATED);
                    } else if (
                        Object.keys(newUser).length !== 0 &&
                        Object.keys(newUser).includes('isEmailVerified') &&
                        !newUser.isEmailVerified
                    ) {
                        setAuthenticated(AuthStatus.REQUIRE_MAIL_VERIFICATION);
                    } else {
                        setAuthenticated(AuthStatus.NOT_AUTHENTICATED);
                    }
                    setTransactionMode(false);
                }
                window.location.reload();
            } else if (event.key === CustomEvents.LOGOUT) {
                // Handle logout event
                setUser(null);
                setAuthenticated(AuthStatus.NOT_AUTHENTICATED);
                setTransactionMode(false);
                // Optionally navigate to login page or other state
                window.location.href = '/login';
            }
        };

        // Add event listener for storage changes
        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [setUser, setAuthenticated, setTransactionMode]);

    return (
        <AuthContext.Provider value={{ authenticated, login, logout, user }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use auth state and functions
export const useAuth = () => useContext(AuthContext);
